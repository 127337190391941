import { Home, Status } from 'components/Routes'
import React from 'react'
import { hot } from 'react-hot-loader'
import { Route, Switch } from 'react-router'
import Navbar from './Navbar'
import Footer from './Footer'
import { Updates } from './Updates'

import './App.less'

const App: React.FunctionComponent = () => (
	<>
		<Navbar />
		<main>
			<Switch>
				<Route exact path="/status/:id" component={Status} />
				<Route exact path="/:id" component={Status} />
				<Route exact path="/" component={Home} />
			</Switch>
			<Updates />
		</main>
		<Footer />
	</>
)

export default hot(module)(App)
