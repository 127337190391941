/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import './Footer.less'

const Footer: React.FunctionComponent = () => (
	<>
		<div className="footer__bg" />
		<footer>
			<article className="footer__content">
				<h2>Ofte Stilte Spørsmål</h2>
				<p>
					<strong>Kan jeg være sikker på estimert leveringsdato?</strong>
					<br />
					<span>
						Vi har sent ut over 4000 Auker og har i en relativt stabil produksjon, men det
						kan forekomme endringer. Vi oppdaterer estimatene 2-3 ganger i uken og sier ifra
						med siste status over så fort det skjer endringer.
					</span>
				</p>

				<p>
					<strong>Jeg må endre adressen min, hva gjør jeg?</strong>
					<br />
					<span>
						Send oss en melding (<a href="mailto:support@auk.eco">support@auk.eco</a> eller
						via{' '}
						<a href="https://support.auk.eco" target="_blank">
							support.auk.eco
						</a>
						) med din nye adresse. Du får en bekreftelse så snart vi har oppdatert adressen
						din.
					</span>
				</p>

				<p>
					<strong>Jeg husker ikke hvilken adresse jeg hadde når jeg bestilte</strong>
					<br />
					<span>
						Se bekreftelses-eposten din for å sjekke om adressen din er riktig, Tips: søk opp
						"Auk" eller "Takk for din forhåndsbestilling!" i inboksen din for å finne
						e-posten.
					</span>
				</p>

				<a className="button" href="https://support.auk.eco" target="_blank">
					Se mer på support.auk.eco
				</a>
			</article>
		</footer>
	</>
)

export default Footer
