import React from 'react'
import { RouteComponentProps } from 'react-router'

import './home.less'

type Props = RouteComponentProps<{ id: string }>

const Home: React.FunctionComponent<Props> = () => (
	<div className="home">
		<h1>Ordrestatus</h1>
		<p>
			Hei! Nå som forhåndssalget er over er ordretrackeren deaktivert. I de aller fleste
			situasjoner vil Auken din bli sendt i løpet av 0-2 virkedager :)
		</p>
	</div>
)

export default Home
