import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import version from './version'

const reducers = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		version
	})

export default reducers
