import React from 'react'

export const Updates: React.FunctionComponent = () => (
	<article className="footer__updates">
		<h2>Siste oppdateringer</h2>
		<p>
			<strong>Oppdatering 6. Oktober</strong>
			<br />
			<span>
				Siden produksjonen startet opp igjen 1. oktober har vi hatt et veldig godt tempo, og
				sendt ut over 500 enheter de siste 5 arbeidsdagene (vi hadde ny dagsrekord med 140
				enheter sendt ut på mandag). Vi forventer at utsendelsesraten på 4-500 pr. uke vil
				opprettholdes fremover.
			</span>
		</p>
		<p>
			<strong>Oppdatering 1. Oktober</strong>
			<br />
			<span>
				Kontaineren er kommet fram og produksjonen har startet opp igjen 🎉 Vi har også fått
				flere på produksjonslinjen, så utsendelser burde gå raskere fra nå av.
			</span>
		</p>
		<p>
			<strong>Oppdatering 23. september</strong>
			<br />
			<span>
				Det har dessverre vært stopp i produksjon grunnet en container som satt fast i havnen i
				Irland og forsinket deler til fabrikken. Transporten er nå oppe og går igjen, og vi
				forventer at utsendelser begynner førstkommende onsdag. Vi beklager så mye for
				forsinkelsen. Vi legger nå inn vesentlig mer sikkerhetsmargin og tettere oppfølgning av
				fremtidige forsendelser for å unngå at dette skjer igjen.
			</span>
		</p>
	</article>
)
