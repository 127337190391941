import React from 'react'
import { Link } from 'react-router-dom'

import './navbar.less'

const Navbar: React.FunctionComponent = () => (
	<nav className="navbar">
		<Link to="/">
			<img src="https://links.auk.eco/static/auk-logo-de134dfa4b105e2a449bf48cfeab029e.svg" />
		</Link>
		<a className="button button--secondary" href="https://support.auk.eco">
			Se mer på support.auk.eco
		</a>
	</nav>
)
export default Navbar
