import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { PartStatus } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'

import './status.less'
import { __DEV__ } from 'consts'

const baseUrl = __DEV__
	? 'http://localhost:3000'
	: 'https://j5d7j0p798.execute-api.eu-central-1.amazonaws.com'

type DateResponse = {
	date?: number
	parts: PartStatus[]
	error?: string
}

type Part = Record<
	string,
	{
		done: boolean
		parts: Part
	}
>

const getDays = (date: Date) => Math.ceil((date.getTime() - Date.now()) / 1000 / 60 / 60 / 24)

const getDeliveryText = (date: Date) => {
	const days = Math.max(getDays(date), 1)
	if (days > 20) return `${Math.round((days * 2) / 7) / 2} uker`
	else if (days === 1) return '1 dag'
	else return `${days} dager`
}

const NotFound: React.FunctionComponent<RouteComponentProps<{ id: string }>> = ({ match }) => {
	const id = match.params.id
	const [deliveryDate, setDate] = useState<Date | 'loading' | null>('loading')
	const [parts, setParts] = useState<PartStatus[] | null>(null)
	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetch(`${baseUrl}/auk-order-status?orderId=${id}`)
				const data: DateResponse = await response.json()
				if (response.ok && data.date) {
					setDate(new Date(data.date + 5 * 24 * 60 * 60 * 1000))
					const newParts: Part = {}
					for (const [main, sub, done] of data.parts) {
						console.log([main, sub, done])
						newParts[main] = { done, parts: {} }
						if (sub) newParts[main].parts[sub] = { done, parts: {} }
						else newParts[main] = { done, parts: {} }
					}
					console.log('result', newParts)
					// setParts(data.parts)
				} else {
					console.error(data.error)
					setDate(null)
				}
			} catch (e) {
				console.error(e)
				setDate(null)
			}
		}
		fetchData()
	}, [id])

	return (
		<div className="status">
			{deliveryDate ? (
				<>
					<h2>
						Viser ordrestatus for{' '}
						<code>
							{id.includes('@') || '#'}
							{id}
						</code>
					</h2>
					{deliveryDate == 'loading' ? (
						<h1>Henter leveringsestimat...</h1>
					) : (
						<>
							<h2>
								Vi forventer at Auken din blir sendt{' '}
								<span style={{ color: 'green' }}>
									{deliveryDate.toLocaleDateString('nb-NO')}
								</span>
							</h2>
							{getDays(deliveryDate) < 25 && (
								<h2>Bare {getDeliveryText(deliveryDate)} igjen!</h2>
							)}
							<p>
								<em>
									Estimatet oppdateres daglig. Du kan bokmerke denne siden for å slippe
									å skrive inn ordrenummer på nytt.
								</em>
							</p>
							{parts?.length && (
								<div className="status__parts">
									<h2>Her er statusen på de individuelle delene:</h2>
									<ul>
										{parts.map(([main, subpart, done]) => (
											<li
												key={`${main}_${subpart}`}
												className={cn(
													{ subpart: subpart.length > 0 },
													`${main}_${subpart}`.toLowerCase()
												)}
											>
												<FontAwesomeIcon
													className="icon"
													size="lg"
													icon={done ? faCheckCircle : faTimesCircle}
												/>
												<span>{subpart || main}</span>
											</li>
										))}
									</ul>
								</div>
							)}
						</>
					)}
				</>
			) : (
				<>
					<h2>
						Fant ingen leveringsdato for <code>#{id}</code>
					</h2>
					<p>
						<a href={window.location.href} target="_self">
							Trykk her
						</a>
						<span>&#32;for å prøve igjen, eller&#32;</span>
						<a href="https://www.auk.eco/pages/kontakt" target="_blank">
							kontakt support
						</a>
					</p>
				</>
			)}
		</div>
	)
}

export default NotFound
